import {createContext, useState, useEffect} from 'react'
import secureLocalStorage from "react-secure-storage";

const CartContext = createContext();

export const CartProvider = ({children}) => {
  const [cart, setCart] = useState(null);
  const [amountCart, setAmountCart] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    getCart();
    getAmountCart();
  }, []);

  useEffect(() => {
    getAmountCart();
    getTotalPrice();
  }, [cart, amountCart, totalPrice])

  const getCart = () => {
    let cartStorage = JSON.parse(secureLocalStorage.getItem("cart"));
    // if (cartStorage)
    //   console.log(cartStorage)
    setCart(cartStorage);
  }

  const getAmountCart = () => {
    let cartStorage = JSON.parse(secureLocalStorage.getItem("cart"));
    if (!cartStorage)
      return;

    let amountLine = 0;
    cartStorage.lines.map((l) => {
      amountLine += l.amount_line;
    })
    setAmountCart(amountLine);
  }

  const getTotalPrice = () => {
    let cartStorage = JSON.parse(secureLocalStorage.getItem("cart"));
    if (!cartStorage)
      return;

    let total = 0;
    cartStorage.lines.map((l) => {
      total += l.amount_line * l.product_price;
    })
    setTotalPrice(Math.round((total + Number.EPSILON) * 100) / 100);
  }

  const addCart = (order, product, amountLine) => {
    let orderData = prepareOrderData(order);
    let lineData = prepareLineData(product, amountLine);

    let cartStorage = JSON.parse(secureLocalStorage.getItem("cart"));
    if (!cartStorage) {
      cartStorage = {};
      cartStorage["lines"] = [];
      if (lineData !== null)
        cartStorage["lines"].push(lineData);
    }
    else if(lineData !== null) {
      let lineIndex = cartStorage.lines.findIndex(l => l.product_code === product.code);
      if (lineIndex === -1) {
        cartStorage["lines"].push(lineData);
      }
      else {
        cartStorage["lines"][lineIndex].amount_line = amountLine;
      }
    }
    cartStorage["order"] = orderData;
    cartStorage["created_at"] = Date.now();
    secureLocalStorage.setItem("cart", JSON.stringify(cartStorage));
    setCart(cartStorage);
  }

  const editAmountItemCart = (codeProduct, amountLine) => {
    let cartStorage = JSON.parse(secureLocalStorage.getItem("cart"));
    if (!cartStorage || cartStorage.lines.length === 0)
      return;

    let lineIndex = cartStorage.lines.findIndex(l => l.product_code === codeProduct);
    if (lineIndex === -1)
      return;

    cartStorage["lines"][lineIndex].amount_line = amountLine;

    secureLocalStorage.setItem("cart", JSON.stringify(cartStorage));
    setCart(cartStorage);
  }

  const removeItemCart = (idProduct) => {
    let cartStorage = JSON.parse(secureLocalStorage.getItem("cart"));
    if (!cartStorage)
      return;

    let lineIndex = cartStorage.lines.findIndex(l => l.product_id === idProduct);
    if (lineIndex === -1)
      return;

    cartStorage["lines"].splice(lineIndex, 1);

    secureLocalStorage.setItem("cart", JSON.stringify(cartStorage));
    setCart(cartStorage);
  }

  const prepareOrderData = (order) => {
    if (order === null)
      return;

    return {
      "user_id": !order.user ? null : order.user.id,
      "user_name": !order.user ? null : order.user.name,
      "user_phone": !order.user ? null : order.user.phone,
      "user_email": !order.user ? null : order.user.email,
      "user_role": !order.user ? null : order.user.role,
      "new_user": !order.user ? null : order.user.new,
      "user_password": !order.user ? null : order.user.password,
      "user_confirm_password": !order.user ? null : order.user.passwordConfirm,
      "total_price" : order.totalPrice,
      "user_address" : order.address,
      "user_billing_address": order.addressBilling,
      "pay_method": order.payMethod,
      'user_cif': !order.user ? null : order.user.cif,
      'address_street': !order.addressIndependent ? null : order.addressIndependent.street,
      'address_zip': !order.addressIndependent ? null : order.addressIndependent.zip,
      'address_town': !order.addressIndependent ? null : order.addressIndependent.town,
      'address_city': !order.addressIndependent ? null : order.addressIndependent.city,
      'address_country': !order.addressIndependent ? null : order.addressIndependent.country,
      'observations': order.observations
    }
  }

  const prepareLineData = (product, amountLine) => {
    if (product === null)
      return null;

    return {
      "product_id": !product ? null : product.id,
      "product_code": !product ? null : product.code,
      "product_name": !product ? null : product.name,
      "product_description": !product ? null : product.description,
      "product_price": !product ? null : product.price,
      "product_amount": !product ? null : product.amount,
      "product_measure": !product ? null : product.measure,
      "product_limit": !product ? null : product.limit,
      "product_image": !product ? null : product.image,
      "amount_line": amountLine
    }
  }

  const removeCart = () => {
    secureLocalStorage.removeItem('cart');
    setCart(null);
  }

  return (
    <CartContext.Provider value={{ cart, addCart, amountCart, totalPrice, removeItemCart, editAmountItemCart, removeCart }}>
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;