import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React, {useContext} from 'react';
import Header from './components/Header/Header';
import ScrollToTop from './components/Header/ScrollToTop'
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage';
import LoginPage from './pages/Authentication/LoginPage';
import RegisterPage from './pages/Authentication/RegisterPage';
import RecoverPassword from './pages/Authentication/RecoverPasswordPage';
import PackagingPage from './pages/public/PackagingPage';
import ProductPage from './pages/public/ProductPage';
import CompanyPage from './pages/public/CompanyPage';
import ContactPage from './pages/public/ContactPage';
import EnvironmentPage from './pages/public/EnvironmentPage';
import BackToTop from './components/Buttons/BackToTop';
import ShopPage from './pages/public/ShopPage';
import ShopDetailPage from './pages/public/ShopDetailPage';
import TestPage from './pages/public/TestPage'
import CartPage from './pages/public/CartPage'
import CheckoutPage from './pages/public/CheckoutPage';
import NotFoundPage from './pages/public/NotFoundPage';
import CookiesPolicyPage from './pages/public/CookiesPolicyPage';
import PrivacyPolicyPage from './pages/public/PrivacyPolicyPage';
import LegalWarningPage from './pages/public/LegalWarningPage';
import DashboardPage from './pages/Admin/DashboardPage';
import DashboardAdminPage from './pages/Admin/DashboardAdminPage';
import { ProtectedRoute } from './components/Account/ProtectedRoute'
import ProfilePage from './pages/Admin/ProfilePage';
import AddressPage from './pages/Admin/AddressPage';
import ProductAdminPage from './pages/Admin/ProductAdminPage';
import StoreContext from './context/StoreContext'
import { UserProvider } from './context/UserContext'
import { CartProvider } from './context/CartContext'
import PagoCompraPage from './pages/public/PagoCompraPage'
import PurcharseSatisfiedPage from './pages/public/PurcharseSatisfiedPage'
import VerifyEmailPage from './pages/public/VerifyEmailPage';
import ResetPasswordPage from './pages/Authentication/ResetPasswordPage';
import SummaryOrderPage from './pages/public/SummaryOrderPage';
import { ProtectedRouteLogin } from './components/Account/ProtectedRouteLogin';
import TpvOKPage from './pages/public/TpvOKPage'
import TpvKOPage from './pages/public/TpvKOPage'

function App() {

  const {isLoading} = useContext(StoreContext);

  if (isLoading )
    return '';

  return (
    <React.Fragment>
      <UserProvider>
        <CartProvider>
          <Router>
            <ScrollToTop />
            <Header />
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/empresa' element={<CompanyPage />} />
              <Route path='/tienda' element={<ShopPage />} />
              <Route path='/tienda/:slug' element={<ShopDetailPage />} />
              <Route path='/carrito' element={<CartPage />} />
              <Route path='/checkout' element={<CheckoutPage/>} />
              <Route path='/productos' element={<ProductPage />} />
              <Route path='/envases' element={<PackagingPage />} />
              <Route path='/entorno' element={<EnvironmentPage />} />
              <Route path='/contacto' element={<ContactPage />} />
              <Route path='/login' element={<ProtectedRouteLogin route={<LoginPage />} />} />
              <Route path='/registro' element={<RegisterPage />} />
              <Route path='/recuperar-password' element={<RecoverPassword />}/>
              <Route path='/restablecer-password/:token/:email' element={<ResetPasswordPage />} />
              <Route path='/politica-cookies' element={<CookiesPolicyPage />}/>
              <Route path='/politica-privacidad' element={<PrivacyPolicyPage />} />
              <Route path='/aviso-legal' element={<LegalWarningPage />} />
              <Route path='/pago-compra/:id/:locator' element={<PagoCompraPage />}/>
              <Route path='/compra-satisfactoria/:id/:locator' element={<PurcharseSatisfiedPage/> }/>
              <Route path='/resumen-pedido/:locator' element={<SummaryOrderPage />} />
              <Route path='/email/verify/already-success' element={<VerifyEmailPage />} />
              <Route path='/email/verify/success' element={<VerifyEmailPage />} />
              <Route path='/tpvok/:locator' element={<TpvOKPage />} />
              <Route path='/tpvko/:locator' element={<TpvKOPage /> } />

              <Route path='*' element={<NotFoundPage />} />

              <Route path='/dashboard' element={<ProtectedRoute route={<DashboardPage />} />} />
              <Route path='/dashboard/perfil' element={<ProtectedRoute route={<ProfilePage />}/>}/>
              <Route path='/dashboard/direccion' element={<ProtectedRoute route={<AddressPage />}/>}/>
              <Route path='/dashboard/direccion/:id' element={<ProtectedRoute route={<AddressPage />} />} />
              <Route path='/dashboard-admin' element={<ProtectedRoute route={<DashboardAdminPage />} />} />
              <Route path='/dashboard-admin/producto' element={<ProtectedRoute route={<ProductAdminPage />} />} />
              <Route path='/dashboard-admin/producto/:id' element={<ProtectedRoute route={<ProductAdminPage />}/>} />

              <Route path='/test' element={<TestPage />} />
            </Routes>
            <Footer />
            <BackToTop />
          </Router>
        </CartProvider>
      </UserProvider>
    </React.Fragment>
  );
}

export default App;
